import nanoid from 'nanoid';
import { format } from 'date-fns';

const countryCurrencies = {
  li: 'CHF',
  cz: 'CZK',
  dk: 'DKK',
  gb: 'GBP',
  hu: 'HUF',
  no: 'NOK',
  pl: 'PLN',
  ro: 'RON',
  se: 'SEK',
};

export function createEmptyInfoItem() {
  return {
    id: nanoid(5),
    amount: '1',
  };
}

function getDate(strDate) {
  return strDate ? new Date(strDate) : null;
}

export function formatDate(date) {
  return format(date, 'dd.MM.yyyy');
}

export function getCurrencyOfCountry(countryCode) {
  return countryCurrencies[countryCode] || 'EUR';
}

export function createClaimFromShipment(shipment) {
  return {
    shipmentId: shipment.id,
    trackingCode: shipment.trackingCode,
    carrierCode: shipment.carrier.code,
    carrierCountryCode: shipment.carrier.countryCode,
    customerName:
      (shipment.isReturnShipment ? shipment.order.recipient.name : shipment.customer.name) || shipment.customer.name,
    firstHubScanDate: getDate(shipment.firstHubScanDate),
    ssHubScanDate: getDate(shipment.ssHubScanDate),
    deliveryDate: getDate(shipment.deliveryDate),
    postalReturnDeliveryDate: getDate(shipment.postalReturnDeliveryDate),
    postalReturnDate: getDate(shipment.postalReturnDate),
    isDelivered: !!shipment.deliveryDate || !!shipment.postalReturnDeliveryDate,
    isReturnShipment: shipment.isReturnShipment,
    deliveryAttemptDate: getDate(shipment.deliveryAttemptDate),
    deliveredToMailboxDate: getDate(shipment.deliveredToMailboxDate),
    weight: shipment.weight || 1,
    netValueCurrency: getCurrencyOfCountry(shipment.customer.country.iso2),
    partialDamageInfo: [createEmptyInfoItem()],
    missingItemInfo: [createEmptyInfoItem()],
    trackingScreenshot: [],
    invoiceDocument: [],
    customerComment: '',
    declarationOfRecipient: [],
    picturesOfDamage: [],
    picturesOfMissingItem: [],
    recipientAddress:
      (shipment.isReturnShipment ? shipment.order.recipient.address : shipment.customer.address) ||
      shipment.customer.address,
    recipientCity:
      (shipment.isReturnShipment ? shipment.order.recipient.city : shipment.customer.city) || shipment.customer.city,
    recipientZipCode:
      (shipment.isReturnShipment ? shipment.order.recipient.zipCode : shipment.customer.zipCode) ||
      shipment.customer.zipCode,
    shopOrderId: shipment.order.shopOrderId,
    deadlines: shipment.deadlines,
  };
}

const eventTypeToClaimType = {
  SELECT_INVESTIGATION: 'investigation',
  SELECT_PARTIAL_DAMAGE: 'partial_damage',
  SELECT_WHOLE_DAMAGE: 'whole_damage',
  SELECT_MISSING_ITEM: 'missing_item',
};

export function isWarehouseDeliveryDateRequired(claim, _, meta) {
  const event = meta.state.event;
  const claimType = eventTypeToClaimType[event?.type] || claim.type;

  const { useWarehouseDelivery, outcome } = claim.deadlines?.[claimType] || {};

  if (!useWarehouseDelivery) {
    return false;
  }

  return outcome === 'early' || outcome === 'past';
}

export function isNonManual(claim) {
  return !!claim.shipmentId;
}

export function isClaimOutsideOfDeadline(claim, _, meta) {
  const event = meta.state.event;
  const claimType = eventTypeToClaimType[event?.type] || claim.type;
  const { outcome } = claim.deadlines?.[claimType] || {};

  // it's better to not compare against "within" because for manual flow we don't have deadlines
  return outcome === 'early' || outcome === 'past';
}

export function isClaimDeliveredToMailbox(claim) {
  return !!claim.deliveredToMailboxDate;
}
